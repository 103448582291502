const countryCodes = {
  "Afghanistan": "AF",
  "Aland Islands": "AX",
  "Albania": "AL",
  "Algeria": "DZ",
  "American Samoa": "AS",
  "Andorra": "AD",
  "Angola": "AO",
  "Anguilla": "AI",
  "Antarctica": "AQ",
  "Antigua And Barbuda": "AG",
  "Argentina": "AR",
  "Armenia": "AM",
  "Aruba": "AW",
  "Australia": "AU",
  "Austria": "AT",
  "Azerbaijan": "AZ",
  "Bahamas": "BS",
  "Bahrain": "BH",
  "Bangladesh": "BD",
  "Barbados": "BB",
  "Belarus": "BY",
  "Belgium": "BE",
  "Belize": "BZ",
  "Benin": "BJ",
  "Bermuda": "BM",
  "Bhutan": "BT",
  "Bolivia": "BO",
  "Bosnia And Herzegovina": "BA",
  "Botswana": "BW",
  "Bouvet Island": "BV",
  "Brazil": "BR",
  "British Indian Ocean Territory": "IO",
  "Brunei Darussalam": "BN",
  "Bulgaria": "BG",
  "Burkina Faso": "BF",
  "Burundi": "BI",
  "Cambodia": "KH",
  "Cameroon": "CM",
  "Canada": "CA",
  "Cape Verde": "CV",
  "Cayman Islands": "KY",
  "Central African Republic": "CF",
  "Chad": "TD",
  "Chile": "CL",
  "China": "CN",
  "Christmas Island": "CX",
  "Cocos (Keeling) Islands": "CC",
  "Colombia": "CO",
  "Comoros": "KM",
  "Congo": "CG",
  "Congo, Democratic Republic": "CD",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  "Cote D'Ivoire": "CI",
  "Croatia": "HR",
  "Cuba": "CU",
  "Cyprus": "CY",
  "Czech Republic": "CZ",
  "Denmark": "DK",
  "Djibouti": "DJ",
  "Dominica": "DM",
  "Dominican Republic": "DO",
  "Ecuador": "EC",
  "Egypt": "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  "Eritrea": "ER",
  "Estonia": "EE",
  "Ethiopia": "ET",
  "Falkland Islands (Malvinas)": "FK",
  "Faroe Islands": "FO",
  "Fiji": "FJ",
  "Finland": "FI",
  "France": "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories": "TF",
  "Gabon": "GA",
  "Gambia": "GM",
  "Georgia": "GE",
  "Germany": "DE",
  "Ghana": "GH",
  "Gibraltar": "GI",
  "Greece": "GR",
  "Greenland": "GL",
  "Grenada": "GD",
  "Guadeloupe": "GP",
  "Guam": "GU",
  "Guatemala": "GT",
  "Guernsey": "GG",
  "Guinea": "GN",
  "Guinea-Bissau": "GW",
  "Guyana": "GY",
  "Haiti": "HT",
  "Heard Island & Mcdonald Islands": "HM",
  "Holy See (Vatican City State)": "VA",
  "Honduras": "HN",
  "Hong Kong": "HK",
  "Hungary": "HU",
  "Iceland": "IS",
  "India": "IN",
  "Indonesia": "ID",
  "Iran, Islamic Republic Of": "IR",
  "Iraq": "IQ",
  "Ireland": "IE",
  "Isle Of Man": "IM",
  "Israel": "IL",
  "Italy": "IT",
  "Jamaica": "JM",
  "Japan": "JP",
  "Jersey": "JE",
  "Jordan": "JO",
  "Kazakhstan": "KZ",
  "Kenya": "KE",
  "Kiribati": "KI",
  "Korea": "KR",
  "North Korea": "KP",
  "Kuwait": "KW",
  "Kyrgyzstan": "KG",
  "Lao People's Democratic Republic": "LA",
  "Latvia": "LV",
  "Lebanon": "LB",
  "Lesotho": "LS",
  "Liberia": "LR",
  "Libyan Arab Jamahiriya": "LY",
  "Liechtenstein": "LI",
  "Lithuania": "LT",
  "Luxembourg": "LU",
  "Macao": "MO",
  "Macedonia": "MK",
  "Madagascar": "MG",
  "Malawi": "MW",
  "Malaysia": "MY",
  "Maldives": "MV",
  "Mali": "ML",
  "Malta": "MT",
  "Marshall Islands": "MH",
  "Martinique": "MQ",
  "Mauritania": "MR",
  "Mauritius": "MU",
  "Mayotte": "YT",
  "Mexico": "MX",
  "Micronesia, Federated States Of": "FM",
  "Moldova": "MD",
  "Monaco": "MC",
  "Mongolia": "MN",
  "Montenegro": "ME",
  "Montserrat": "MS",
  "Morocco": "MA",
  "Mozambique": "MZ",
  "Myanmar": "MM",
  "Namibia": "NA",
  "Nauru": "NR",
  "Nepal": "NP",
  "Netherlands": "NL",
  "Netherlands Antilles": "AN",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  "Nicaragua": "NI",
  "Niger": "NE",
  "Nigeria": "NG",
  "Niue": "NU",
  "Norfolk Island": "NF",
  "Northern Mariana Islands": "MP",
  "Norway": "NO",
  "Oman": "OM",
  "Pakistan": "PK",
  "Palau": "PW",
  "Palestinian Territory, Occupied": "PS",
  "Panama": "PA",
  "Papua New Guinea": "PG",
  "Paraguay": "PY",
  "Peru": "PE",
  "Philippines": "PH",
  "Pitcairn": "PN",
  "Poland": "PL",
  "Portugal": "PT",
  "Puerto Rico": "PR",
  "Qatar": "QA",
  "Reunion": "RE",
  "Romania": "RO",
  "Russia": "RU",
  "Rwanda": "RW",
  "Saint Barthelemy": "BL",
  "Saint Helena": "SH",
  "Saint Kitts And Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin": "MF",
  "Saint Pierre And Miquelon": "PM",
  "Saint Vincent And Grenadines": "VC",
  "Samoa": "WS",
  "San Marino": "SM",
  "Sao Tome And Principe": "ST",
  "Saudi Arabia": "SA",
  "Senegal": "SN",
  "Serbia": "RS",
  "Seychelles": "SC",
  "Sierra Leone": "SL",
  "Singapore": "SG",
  "Slovakia": "SK",
  "Slovenia": "SI",
  "Solomon Islands": "SB",
  "Somalia": "SO",
  "South Africa": "ZA",
  "South Georgia And Sandwich Islands": "GS",
  "Spain": "ES",
  "Sri Lanka": "LK",
  "Sudan": "SD",
  "Suriname": "SR",
  "Svalbard And Jan Mayen": "SJ",
  "Swaziland": "SZ",
  "Sweden": "SE",
  "Switzerland": "CH",
  "Syrian Arab Republic": "SY",
  "Taiwan": "TW",
  "Tajikistan": "TJ",
  "Tanzania": "TZ",
  "Thailand": "TH",
  "Timor-Leste": "TL",
  "Togo": "TG",
  "Tokelau": "TK",
  "Tonga": "TO",
  "Trinidad And Tobago": "TT",
  "Tunisia": "TN",
  "Turkey": "TR",
  "Turkmenistan": "TM",
  "Turks And Caicos Islands": "TC",
  "Tuvalu": "TV",
  "Uganda": "UG",
  "Ukraine": "UA",
  "United Arab Emirates": "AE",
  "United Kingdom": "GB",
  "United States": "US",
  "United States Outlying Islands": "UM",
  "Uruguay": "UY",
  "Uzbekistan": "UZ",
  "Vanuatu": "VU",
  "Venezuela": "VE",
  "Vietnam": "VN",
  "Virgin Islands, British": "VG",
  "Virgin Islands, U.S.": "VI",
  "Wallis And Futuna": "WF",
  "Western Sahara": "EH",
  "Yemen": "YE",
  "Zambia": "ZM",
  "Zimbabwe": "ZW"
}

const countryCoordinates = {
  "AF": { lat: 33.93911, lng: 67.709953 },
  "AX": { lat: 60.1785, lng: 19.9156 },
  "AL": { lat: 41.1533, lng: 20.1683 },
  "DZ": { lat: 28.0339, lng: 1.6596 },
  "AS": { lat: -14.271, lng: -170.132 },
  "AD": { lat: 42.5063, lng: 1.5218 },
  "AO": { lat: -11.2027, lng: 17.8739 },
  "AI": { lat: 18.2206, lng: -63.0686 },
  "AQ": { lat: -75.250973, lng: -0.071389 },
  "AG": { lat: 17.0608, lng: -61.7964 },
  "AR": { lat: -38.4161, lng: -63.6167 },
  "AM": { lat: 40.0691, lng: 45.0382 },
  "AW": { lat: 12.5211, lng: -69.9683 },
  "AU": { lat: -25.2744, lng: 133.7751 },
  "AT": { lat: 47.5162, lng: 14.5501 },
  "AZ": { lat: 40.1431, lng: 47.5769 },
  "BS": { lat: 25.0343, lng: -77.3963 },
  "BH": { lat: 25.9304, lng: 50.6378 },
  "BD": { lat: 23.685, lng: 90.3563 },
  "BB": { lat: 13.1939, lng: -59.5432 },
  "BY": { lat: 53.7098, lng: 27.9534 },
  "BE": { lat: 50.8503, lng: 4.3517 },
  "BZ": { lat: 17.1899, lng: -88.4976 },
  "BJ": { lat: 9.3077, lng: 2.3158 },
  "BM": { lat: 32.3078, lng: -64.7505 },
  "BT": { lat: 27.5142, lng: 90.4336 },
  "BO": { lat: -16.2902, lng: -63.5887 },
  "BA": { lat: 43.9159, lng: 17.6791 },
  "BW": { lat: -22.3285, lng: 24.6849 },
  "BV": { lat: -54.4232, lng: 3.4132 },
  "BR": { lat: -14.235, lng: -51.9253 },
  "IO": { lat: -6.3432, lng: 71.8765 },
  "BN": { lat: 4.5353, lng: 114.7277 },
  "BG": { lat: 42.7339, lng: 25.4858 },
  "BF": { lat: 12.2383, lng: -1.5616 },
  "BI": { lat: -3.3731, lng: 29.9189 },
  "KH": { lat: 12.5657, lng: 104.991 },
  "CM": { lat: 7.3697, lng: 12.3547 },
  "CA": { lat: 56.1304, lng: -106.3468 },
  "CV": { lat: 16.5388, lng: -23.0418 },
  "KY": { lat: 19.3133, lng: -81.2546 },
  "CF": { lat: 6.6111, lng: 20.9394 },
  "TD": { lat: 15.4542, lng: 18.7322 },
  "CL": { lat: -35.6751, lng: -71.543 },
  "CN": { lat: 35.8617, lng: 104.1954 },
  "CX": { lat: -10.4475, lng: 105.6904 },
  "CC": { lat: -12.1642, lng: 96.8709 },
  "CO": { lat: 4.5709, lng: -74.2973 },
  "KM": { lat: -11.875, lng: 43.8722 },
  "CG": { lat: -0.228, lng: 15.8277 },
  "CD": { lat: -4.0383, lng: 21.7587 },
  "CK": { lat: -21.2367, lng: -159.7777 },
  "CR": { lat: 9.7489, lng: -83.7534 },
  "CI": { lat: 7.5399, lng: -5.5471 },
  "HR": { lat: 45.1, lng: 15.2 },
  "CU": { lat: 21.5218, lng: -77.7812 },
  "CY": { lat: 35.1264, lng: 33.4299 },
  "CZ": { lat: 49.8175, lng: 15.473 },
  "DK": { lat: 56.2639, lng: 9.5018 },
  "DJ": { lat: 11.8251, lng: 42.5903 },
  "DM": { lat: 15.415, lng: -61.371 },
  "DO": { lat: 18.7357, lng: -70.1627 },
  "EC": { lat: -1.8312, lng: -78.1834 },
  "EG": { lat: 26.8206, lng: 30.8025 },
  "SV": { lat: 13.7942, lng: -88.8965 },
  "GQ": { lat: 1.6508, lng: 10.2679 },
  "ER": { lat: 15.1794, lng: 39.7823 },
  "EE": { lat: 58.5953, lng: 25.0136 },
  "ET": { lat: 9.145, lng: 40.4897 },
  "FK": { lat: -51.7963, lng: -59.5236 },
  "FO": { lat: 61.8926, lng: -6.9118 },
  "FJ": { lat: -17.7134, lng: 178.065 },
  "FI": { lat: 61.9241, lng: 25.7482 },
  "FR": { lat: 46.6034, lng: 1.8883 },
  "GF": { lat: 3.9339, lng: -53.1258 },
  "PF": { lat: -17.6797, lng: -149.4068 },
  "TF": { lat: -49.2804, lng: 69.3486 },
  "GA": { lat: -0.8037, lng: 11.6094 },
  "GM": { lat: 13.4432, lng: -15.3101 },
  "GE": { lat: 42.3154, lng: 43.3569 },
  "DE": { lat: 51.1657, lng: 10.4515 },
  "GH": { lat: 7.9465, lng: -1.0232 },
  "GI": { lat: 36.1408, lng: -5.3536 },
  "GR": { lat: 39.0742, lng: 21.8243 },
  "GL": { lat: 71.7069, lng: -42.6043 },
  "GD": { lat: 12.1165, lng: -61.679 },
  "GP": { lat: 16.265, lng: -61.551 },
  "GU": { lat: 13.4443, lng: 144.7937 },
  "GT": { lat: 15.7835, lng: -90.2308 },
  "GG": { lat: 49.4657, lng: -2.5853 },
  "GN": { lat: 9.9456, lng: -9.6966 },
  "GW": { lat: 11.8037, lng: -15.1804 },
  "GY": { lat: 4.8604, lng: -58.9302 },
  "HT": { lat: 18.9712, lng: -72.2852 },
  "HM": { lat: -53.0818, lng: 73.5042 },
  "VA": { lat: 41.9029, lng: 12.4534 },
  "HN": { lat: 15.2, lng: -86.2419 },
  "HK": { lat: 22.3193, lng: 114.1694 },
  "HU": { lat: 47.1625, lng: 19.5033 },
  "IS": { lat: 64.9631, lng: -19.0208 },
  "IN": { lat: 20.5937, lng: 78.9629 },
  "ID": { lat: -0.7893, lng: 113.9213 },
  "IR": { lat: 32.4279, lng: 53.688 },
  "IQ": { lat: 33.2232, lng: 43.6793 },
  "IE": { lat: 53.4129, lng: -8.2439 },
  "IM": { lat: 54.2361, lng: -4.5481 },
  "IL": { lat: 31.0461, lng: 34.8516 },
  "IT": { lat: 41.8719, lng: 12.5674 },
  "JM": { lat: 18.1096, lng: -77.2975 },
  "JP": { lat: 36.2048, lng: 138.2529 },
  "JE": { lat: 49.2144, lng: -2.1313 },
  "JO": { lat: 30.5852, lng: 36.2384 },
  "KZ": { lat: 48.0196, lng: 66.9237 },
  "KE": { lat: -0.0236, lng: 37.9062 },
  "KI": { lat: -3.3704, lng: -168.734 },
  "KR": { lat: 35.9078, lng: 127.7669 },
  "KP": { lat: 40.3399, lng: 127.5101 },
  "KW": { lat: 29.3117, lng: 47.4818 },
  "KG": { lat: 41.2044, lng: 74.7661 },
  "LA": { lat: 19.8563, lng: 102.4955 },
  "LV": { lat: 56.8796, lng: 24.6032 },
  "LB": { lat: 33.8547, lng: 35.8623 },
  "LS": { lat: -29.6099, lng: 28.2336 },
  "LR": { lat: 6.4281, lng: -9.4295 },
  "LY": { lat: 26.3351, lng: 17.2283 },
  "LI": { lat: 47.166, lng: 9.5554 },
  "LT": { lat: 55.1694, lng: 23.8813 },
  "LU": { lat: 49.8153, lng: 6.1296 },
  "MO": { lat: 22.1987, lng: 113.5439 },
  "MK": { lat: 41.6086, lng: 21.7453 },
  "MG": { lat: -18.7669, lng: 46.8691 },
  "MW": { lat: -13.2543, lng: 34.3015 },
  "MY": { lat: 4.2105, lng: 101.9758 },
  "MV": { lat: 3.2028, lng: 73.2207 },
  "ML": { lat: 17.5707, lng: -3.9962 },
  "MT": { lat: 35.9375, lng: 14.3754 },
  "MH": { lat: 7.1315, lng: 171.1845 },
  "MQ": { lat: 14.6415, lng: -61.0242 },
  "MR": { lat: 21.0079, lng: -10.9408 },
  "MU": { lat: -20.3484, lng: 57.5522 },
  "YT": { lat: -12.8275, lng: 45.1662 },
  "MX": { lat: 23.6345, lng: -102.5528 },
  "FM": { lat: 7.4256, lng: 150.5508 },
  "MD": { lat: 47.4116, lng: 28.3699 },
  "MC": { lat: 43.7503, lng: 7.4128 },
  "MN": { lat: 46.8625, lng: 103.8467 },
  "ME": { lat: 42.7087, lng: 19.3744 },
  "MS": { lat: 16.7425, lng: -62.1874 },
  "MA": { lat: 31.7917, lng: -7.0926 },
  "MZ": { lat: -18.6657, lng: 35.5296 },
  "MM": { lat: 21.9162, lng: 95.956 },
  "NA": { lat: -22.9576, lng: 18.4904 },
  "NR": { lat: -0.5228, lng: 166.9315 },
  "NP": { lat: 28.3949, lng: 84.124 },
  "NL": { lat: 52.1326, lng: 5.2913 },
  "AN": { lat: 12.2261, lng: -69.0601 },
  "NC": { lat: -20.9043, lng: 165.618 },
  "NZ": { lat: -40.9006, lng: 174.886 },
  "NI": { lat: 12.8654, lng: -85.2072 },
  "NE": { lat: 17.6078, lng: 8.0817 },
  "NG": { lat: 9.082, lng: 8.6753 },
  "NU": { lat: -19.0544, lng: -169.8672 },
  "NF": { lat: -29.0408, lng: 167.9547 },
  "MP": { lat: 15.0979, lng: 145.6739 },
  "NO": { lat: 60.472, lng: 8.4689 },
  "OM": { lat: 21.4735, lng: 55.9754 },
  "PK": { lat: 30.3753, lng: 69.3451 },
  "PW": { lat: 7.5149, lng: 134.5825 },
  "PS": { lat: 31.9522, lng: 35.2332 },
  "PA": { lat: 8.5379, lng: -80.7821 },
  "PG": { lat: -6.314993, lng: 143.95555 },
  "PY": { lat: -23.4425, lng: -58.4438 },
  "PE": { lat: -9.19, lng: -75.0152 },
  "PH": { lat: 12.8797, lng: 121.774 },
  "PN": { lat: -24.7036, lng: -127.4393 },
  "PL": { lat: 51.9194, lng: 19.1451 },
  "PT": { lat: 39.3999, lng: -8.2245 },
  "PR": { lat: 18.2208, lng: -66.5901 },
  "QA": { lat: 25.3548, lng: 51.1839 },
  "RE": { lat: -21.1151, lng: 55.5364 },
  "RO": { lat: 45.9432, lng: 24.9668 },
  "RU": { lat: 61.524, lng: 105.3188 },
  "RW": { lat: -1.9403, lng: 29.8739 },
  "BL": { lat: 17.9, lng: -62.8333 },
  "SH": { lat: -24.1435, lng: -10.0307 },
  "KN": { lat: 17.3578, lng: -62.783 },
  "LC": { lat: 13.9094, lng: -60.9789 },
  "MF": { lat: 18.0708, lng: -63.0501 },
  "PM": { lat: 46.9419, lng: -56.2711 },
  "VC": { lat: 13.2528, lng: -61.1971 },
  "WS": { lat: -13.759, lng: -172.1046 },
  "SM": { lat: 43.9424, lng: 12.4578 },
  "ST": { lat: 0.1864, lng: 6.6131 },
  "SA": { lat: 23.8859, lng: 45.0792 },
  "SN": { lat: 14.4974, lng: -14.4524 },
  "RS": { lat: 44.0165, lng: 21.0059 },
  "SC": { lat: -4.6796, lng: 55.492 },
  "SL": { lat: 8.4606, lng: -11.7799 },
  "SG": { lat: 1.3521, lng: 103.8198 },
  "SK": { lat: 48.669, lng: 19.699 },
  "SI": { lat: 46.1512, lng: 14.9955 },
  "SB": { lat: -9.6457, lng: 160.1562 },
  "SO": { lat: 5.1521, lng: 46.1996 },
  "ZA": { lat: -30.5595, lng: 22.9375 },
  "GS": { lat: -54.4296, lng: -36.5879 },
  "ES": { lat: 40.4637, lng: -3.7492 },
  "LK": { lat: 7.8731, lng: 80.7718 },
  "SD": { lat: 12.8628, lng: 30.2176 },
  "SR": { lat: 3.9193, lng: -56.0278 },
  "SJ": { lat: 77.5536, lng: 23.6703 },
  "SZ": { lat: -26.5225, lng: 31.4659 },
  "SE": { lat: 60.1282, lng: 18.6435 },
  "CH": { lat: 46.8182, lng: 8.2275 },
  "SY": { lat: 34.8021, lng: 38.9968 },
  "TW": { lat: 23.6978, lng: 120.9605 },
  "TJ": { lat: 38.861, lng: 71.2761 },
  "TZ": { lat: -6.369, lng: 34.8888 },
  "TH": { lat: 15.870, lng: 100.9925 },
  "TL": { lat: -8.8742, lng: 125.7275 },
  "TG": { lat: 8.6195, lng: 0.8248 },
  "TK": { lat: -9.2005, lng: -171.848 },
  "TO": { lat: -21.1789, lng: -175.1982 },
  "TT": { lat: 10.6918, lng: -61.2225 },
  "TN": { lat: 33.8869, lng: 9.5375 },
  "TR": { lat: 38.9637, lng: 35.2433 },
  "TM": { lat: 38.9697, lng: 59.5563 },
  "TC": { lat: 21.694, lng: -71.7979 },
  "TV": { lat: -7.1095, lng: 179.194 },
  "UG": { lat: 1.3733, lng: 32.2903 },
  "UA": { lat: 48.3794, lng: 31.1656 },
  "AE": { lat: 23.4241, lng: 53.8478 },
  "GB": { lat: 55.3781, lng: -3.436 },
  "US": { lat: 37.0902, lng: -95.7129 },
  "UM": { lat: 19.2823, lng: 166.647 },
  "UY": { lat: -32.5228, lng: -55.7658 },
  "UZ": { lat: 41.3775, lng: 64.5853 },
  "VU": { lat: -15.3767, lng: 166.9592 },
  "VE": { lat: 6.4238, lng: -66.5897 },
  "VN": { lat: 14.0583, lng: 108.2772 },
  "VG": { lat: 18.4207, lng: -64.6399 },
  "VI": { lat: 18.3358, lng: -64.8963 },
  "WF": { lat: -13.7688, lng: -177.1561 },
  "EH": { lat: 24.2155, lng: -12.8858 },
  "YE": { lat: 15.5527, lng: 48.5164 },
  "ZM": { lat: -13.1339, lng: 27.8493 },
  "ZW": { lat: -19.0154, lng: 29.1549 }
}

export { countryCodes, countryCoordinates };